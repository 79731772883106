<script setup>
import { ref, nextTick } from "vue";

import { QSkeleton, useQuasar } from "quasar";

import {
  IonItem,
  IonList,
  IonCard,
  IonCardContent,
  IonLabel,
  IonCheckbox,
  IonListHeader,
} from "@ionic/vue";

import { useRoute } from "vue-router";

import { useGlobalStore } from "../store/global";

const store = useGlobalStore();

const route = useRoute();

const $q = useQuasar();

const props = defineProps({
  items: Array,
  title: String,
  propName: String,
  model: { required: true },
});

const capitalize = (value) => {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const isChecked = (propName, itemId) => {
  return store.filters[propName]?.includes(itemId);
};

const update = async (propName, item) => {
  store.imageLoadingState = true;
  await nextTick(); // Ensure the UI updates before heavy computation

  // Perform heavy computation after UI update
  store.models = [];
  const models = await store.toggleFilter(propName, item.id, route);

  if (models.length === 0) {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message:
        "Helaas, deze zoekopdracht geeft geen resultaten! Gebruik minder filters.",
    });
  } else {
    store.models = models.slice(0, store.modelsPerPage);
    store.imageLoadingState = false;
    store.filteredModels = models;
  }
};
</script>

<template>
  <ion-card style="width:100%;margin-top: 12px;margin-right: 12px;margin-left: 12px;margin-right: 12px">
    <ion-card-content>
      <template v-if="store.filterLoadingState">
        <ion-list
          :class="[!store.filterLoadingState ? 'hidden' : 'visible']"
        >
          <ion-list-header style="opacity: 0.95">
            <q-skeleton
              type="rect"
              animation="fade"
              height="26px"
              width="80px"
            />
          </ion-list-header>
          <template v-for="i in 2" :key="i">
            <ion-item style="opacity: 0.95" :detail="false" lines="inset">
              <q-skeleton
                animation="fade"
                style="margin: 12px"
                size="26px"
                type="rect"
              />
              <q-skeleton
                animation="fade"
                style="margin-left: 2px"
                width="70px"
                type="QBadge"
              />
            </ion-item>
            <ion-item
              style="opacity: 0.95"
              v-if="i < 4"
              :detail="false"
              lines="inset"
            >
              <q-skeleton
                animation="fade"
                style="margin: 12px"
                size="26px"
                type="rect"
              />
              <q-skeleton
                animation="fade"
                style="margin-left: 2px"
                width="50px"
                type="QBadge"
              />
            </ion-item>
          </template>
        </ion-list>
      </template>

      <template v-else>
        <ion-list
            :class="[!store.filterLoadingState ? 'visible' : 'hidden']"
        >
          <ion-list-header>
            <ion-label>{{ title }}</ion-label>
          </ion-list-header>
          <ion-item
            v-for="(item, key) in items"
            :key="key"
            :detail="false"
            lines="full"
          >
            <ion-checkbox
              :checked="isChecked(propName, item.id)"
              :value="item.id"
              justify="start"
              label-placement="end"
              slot="start"
              color="secondary"
              @ionChange="update(propName, item)"
            >
              {{ capitalize(item.item) }}
            </ion-checkbox>
          </ion-item>
        </ion-list>
      </template>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
.visible {
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s;
}

.hidden {
  z-index: 0;
  opacity: 0;
}
</style>
