import {register} from 'register-service-worker';
import {alertController} from "@ionic/vue";

if (process.env.NODE_ENV === 'production') {
	
	register(`${process.env.BASE_URL}service-worker.js`, {
		async ready() {
			console.log(
				'App is being served from cache by a service worker.\n' +
				'For more details, visit https://goo.gl/AFskqB'
			)
		},
		registered() {
			console.log('Service worker has been registered.')
		},
		cached() {
			console.log('Content has been cached for offline use.')
		},
		updatefound(event) {
			console.log('New content is available; please refresh.', event)
			
			async function presentAlertConfirm() {
				const alert = await alertController
					.create({
						header: 'Er is een content update!',
						message: 'Als u op verder klikt wordt de app opnieuw opgestart/herladen.',
						buttons: [
							{
								text: 'Nee',
								role: 'cancel',
								cssClass: 'secondary',
								id: 'cancel-button',
								handler: blah => {
									console.log('Confirm Cancel:', blah)
								}
							},
							{
								text: 'Ja',
								id: 'confirm-button',
								handler: async () => {
									window.location.reload(true);
									console.log('Confirm Okay')
								},
							},
						],
					});
				return alert.present();
			}
			
			presentAlertConfirm();
		},
		updated(event) {
			console.log('New content is available; please refresh.', event)
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.')
		},
		error(error) {
			console.error('Error during service worker registration:', error)
		},
	})
}

